import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedModelService } from '../../services/shared-model.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
    title: string;
  loggedIn!: boolean;
  registerLink: string = environment.registerLink;
  constructor(private router: Router, private shared: SharedModelService) {
        this.title = 'Securitas Technology - Applikationer';
        shared.loggedInStatusChanged.subscribe(statusChanged => this.loggedInStatusChanged(statusChanged));
    }

  ngOnInit() {
  }

 

  public logout() {
      localStorage.removeItem('ticket');
      localStorage.removeItem('name');
      localStorage.removeItem('token');
      //this.auth.setIdentity(false);
      this.shared.setLoggedInStatus(false);
      location.href = "/";
  }
  public start() {
      this.router.navigate(['start']);
  }
  public login() {
      this.router.navigate(['login']);
  }
 
  public notifications() {
    this.router.navigate(['notifications']);
  }
  public loggedInStatusChanged(status: boolean) {
      this.loggedIn = status;
  }
}
