<div class="app-nav">
    <div class="container">
        <div></div>
    </div>
</div>
<div class="app-nav-sub-menu">
    <div class="container">
        <ul [hidden]="false">
            <li class="app-link" (click)="start()" *ngIf="loggedIn"><i class="sign out icon"></i> Start</li>
            <li class="app-link" (click)="login()" *ngIf="!loggedIn"><i class="sign out icon"></i> Logga in</li>
            <li class="app-link" *ngIf="!loggedIn" style="float:right;padding-right:2px;"><i class="key icon"></i> <a [href]="registerLink" target="_blank">Registrera</a></li>
            <li class="app-link" (click)="notifications()" *ngIf="false"><i class="sign out icon"></i>Notifieringar</li>
            <li class="app-link" (click)="logout()" *ngIf="loggedIn" style="float:right;padding-right:2px;"><i class="sign out icon" ></i> Logga ut</li>
        </ul>
    </div>
</div>
