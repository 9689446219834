import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-kpi-rev',
  templateUrl: './kpi-rev.component.html',
  styleUrls: ['./kpi-rev.component.css']
})
export class KpiRevComponent implements OnInit {
  @Input() revData: any;
    // Pie
    public pieChartOptions: ChartOptions<'pie'> = {
      responsive: false,
    };
    public pieChartLabels = [ [ 'Download', 'Sales' ], [ 'In', 'Store', 'Sales' ], 'Mail Sales' ];
   
    public pieChartDatasets = [ {
      data: [ 300, 500, 100 ]
    } ];
    public pieChartLegend = true;
    public pieChartPlugins = [];
  constructor() { 
    //this.pieChartDatasets[0].data = this.revData.data;
  }

  ngOnInit() {
  }
  chartHovered(arg: any) {
    console.log(arg);
  }
  chartClicked(arg: any) {

  }
}

