import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Item } from '../../viewmodels/list-item';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {
    items: Array<Item>;
    Name: string;
    Ticket: string;
    Message: string = 'Du är inte inloggad. Har du inget konto behöver du registrera dig först.';
    loggedOut!: boolean;

    constructor(private service: DataService, private queryParams: ActivatedRoute) {
        this.items = [];
        this.Name = '';
        this.Ticket = localStorage.getItem('ticket') ?? '';
    }

    ngOnInit() {
        
        if (this.Ticket != undefined) {
              this.Name = '';
              this.getUser();
              this.getItems();
          }
          else {
              this.loggedOut = true;
          } 
        
    }
    private getItems() {
      this.service.getItems(this.Ticket).then((data: Array<any>) => {
        console.log(data);
            this.items = data;
        }).catch(x => {
            console.log('ajax call error');
        });
    }
    private getUser() {
        this.service.getUser(this.Ticket).then((x: any) => {
            console.log(x);
            if (x.Valid) {
                this.Name = x.Name;
                this.Ticket = x.Ticket;
                localStorage.setItem('name', this.Name);
                this.getItems();
                this.loggedOut = false;
            }
            else
            {
                this.Message = this.Message + ", " + x.Name;
                this.loggedOut = true;
            }
        });
    }

}
