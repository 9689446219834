<span>{{item.header}}</span>
<canvas baseChart
        [data]="item.data"
        [labels]="item.labels"
        [options]="item.options"
        
        [type]="item.type"
        (chartHover)="chartHovered($event)"
        (chartClick)="chartClicked($event)"></canvas>
<div *ngFor="let l of item.labels; let in = index">{{l}}: {{item.data[in]}}</div>
