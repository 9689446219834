<h2 *ngIf="!recover">Registrering</h2>
<p *ngIf="!recover">
  För att få tillgång till "Happy" behöver du skapa ett konto.
  Du anger AnvändarID och får ett tillfälligt lösenord skickat till dig.
</p>
<h2 *ngIf="recover">Glömt lösenord</h2>
<p *ngIf="recover">
  Om du har glömt ditt lösenord så kan du återställa det här.
  Efter att du fått ditt tillfälliga lösenord via mail så behöver du byta lösenord (steg 2).
</p>
<div role="group" aria-label="..." *ngIf="false">  
  <button type="button" class="btn btn-primary" (click)="changeState(2)" [disabled]="emCode == null">Jag har redan ett temporärt lösenord</button>  
  <br /><br />
  <input type="text" [(ngModel)]="emCode" class="form-control" placeholder="Ange AnvändarID:"/>
</div>
<div *ngIf="state.stepOneVisible">
  <i class="fa fa-spinner fa-spin" style="font-size:24px" *ngIf="state.waiting"></i>
  <label>Steg 1 av 2.</label>
  <div class="input-group">  
    <input type="text" class="form-control" [(ngModel)]="emCode" placeholder="Ange AnvändarID:" (keyup.enter)="step_one()"/>
    <div class="input-group-btn">
      <button class="btn btn-primary" (click)="step_one()">
        <i class="glyphicon glyphicon-register"></i> Skicka
      </button>
    </div>
  </div>
  <br />
</div>



<div *ngIf="state.stepTwoVisible">
  <i class="fa fa-spinner fa-spin" style="font-size:24px" *ngIf="state.waiting"></i>
  <label>Steg 2 av 2.</label>
  <div class="input-group">
    <input type="text" class="form-control" [(ngModel)]="oldPassword" placeholder="Ange tillfälligt lösenord:" />
    <!--<label for="basic-url">Your vanity URL</label>-->
    <small>Lösenordet måste vara minst 6 tecken långt, innehålla ett specialtecken och minst en liten/stor bokstav</small>
    <input type="password" class="form-control" [(ngModel)]="newPassword" placeholder="Ange personligt lösenord:" />
    <br />
    <input type="password" class="form-control" [(ngModel)]="newPasswordConfirm" placeholder="Ange personligt lösenord igen:" (keyup.enter)="step_two()" />
    <br />
    <button class="btn btn-primary btn-block" (click)="step_two()">
      <i class="glyphicon glyphicon-register"></i> Skicka
    </button>
  </div>
  <br />
</div>



<div *ngIf="state.accountCreatedVisible">
  <div class="alert alert-success" *ngIf="!recover">
    <strong>Nu är det klart!</strong> Ditt konto är nu skapat och du kan logga in med ditt AnvändarID/lösenord.
  </div>
  <div class="alert alert-success" *ngIf="recover">
    <strong>Nu är det klart!</strong> Ditt lösenord är nu ändrat och du kan logga in med din AnvändarID/lösenord.
  </div>
  <button class="btn btn-primary" (click)="login()">Till inloggningssidan</button>
</div>
<div *ngIf="state.error" style="color:red;">
  <ul *ngFor="let e of state.errors">
    <li>{{e}}</li>
  </ul>
</div>
