<div class="col-sm-6 col-md-4 col-lg-4">
  <div class="row">    
    <div class="col-sm-12">
      <label>Email</label><input class="form-control" type="text" [(ngModel)]="username"/>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <label>Lösenord</label><input class="form-control" type="password" (keyup.enter)="login()" [(ngModel)]="password"/>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">

    </div>
    <div class="col-sm-6 padding-10">
      <button class="btn btn-primary form-control" (click)="login()">Logga in</button>
    </div>
    <!--<div class="col-sm-6 padding-10">
      <button class="btn btn-primary form-control" (click)="login_openid()">Logga in (OpenID)</button>
    </div>
    <div class="col-sm-6 padding-10">
      <button class="btn btn-primary form-control" (click)="logout_openid()">Logga ut (OpenID)</button>
    </div>-->
  </div>
  <div class="row" *ngIf="error">    
    <div class="col-sm-12 padding-10 error">
      {{errorMessage}}
    </div>
  </div>
</div>
<div class="col-sm-4">
  <div class="row">
    <div class="col-sm-12 padding-10">
      Glömt dina inloggningsuppgifter?
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 padding-10">
      <a [href]="recoverLink" target="_blank">Återställ ditt lösenord</a>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 padding-10">
      Har du inget konto ännu?
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 padding-10">
      <a [href]="registerLink" target="_blank">Registrera nytt konto</a>
    </div>
  </div>
</div>
