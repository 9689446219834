import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  state: any;
  emCode!: string;
  oldPassword!: string;
  newPassword!: string;
  newPasswordConfirm!: string;
  @Input() recover!: boolean;
  constructor(private service: DataService, private router: Router) {
      this.state = {
          stepOneVisible: true,
          stepTwoVisible: false,
          accountCreatedVisible: false,
          waiting: false,
          error: false,
          errors: []
      };
  }

  ngOnInit() {
  }
  changeState(state: number)
  {
      if (state == 1) {
          this.state.stepOneVisible = true;
          this.state.stepTwoVisible = false;
          this.state.accountCreatedVisible = false;
      }
      else if (state == 2)
      {
          this.state.stepOneVisible = false;
          this.state.stepTwoVisible = true;
          this.state.accountCreatedVisible = false;
      }
  }
  private getErrors(e: any) {
      var error = e.error;
      this.state.errors = [];
      for (var prop in error.ModelState) {

        var arr = error.ModelState[prop];
        console.log(arr);
        for (var i = 0; i < arr.length; i++) {
            console.log(arr[i]);
            this.state.errors.push(arr[i]);
        }
      }
  }
  step_one() {
      this.state.error = false;
      this.state.errors = [];
      this.state.waiting = true;
      if (this.recover)
      {
          this.service.recoverAccount(this.emCode).then((x: any) => {
              console.log(x);
              this.state.waiting = false;
              this.state.stepOneVisible = false;
              this.state.stepTwoVisible = true;
              this.state.accountCreatedVisible = false;
              this.state.error = false;
              this.state.errors = [];
          }).catch((e: any) => {
              this.state.error = true;
              this.state.waiting = false;
              this.getErrors(e);
          });
      }
      else
      {
        this.service.registerAccount(this.emCode).then((x: any) => {
          console.log(x);
          this.state.waiting = false;
          this.state.stepOneVisible = false;
          this.state.stepTwoVisible = true;
          this.state.accountCreatedVisible = false;
          this.state.error = false;
          this.state.errors = [];
        }).catch((e: any) => {
            this.state.error = true;
            this.state.waiting = false;
            this.getErrors(e);
          });
      }
  }
  step_two() {
      this.state.waiting = true;
      this.state.error = false;
      this.state.errors = [];

      if (this.newPassword !== this.newPasswordConfirm)
      {
          this.state.waiting = false;
          this.state.error = true;
          this.state.errors.push('Lösenorden matchar inte varandra');
      }
      else
      {      
        this.service.changePasswordLogin(this.emCode, this.oldPassword, this.newPassword, this.newPasswordConfirm).then((x: any) => {
            this.state.waiting = false;
            this.state.stepOneVisible = false;
            this.state.stepTwoVisible = false;
            this.state.accountCreatedVisible = true;
        }).catch((e: any) => {
            this.state.error = true;
            this.state.waiting = false;
            this.getErrors(e);
        });
      }
  }
  
  login() {
      this.router.navigate(['login']);
  }
}
