import { Injectable } from '@angular/core';
import { Item } from '../viewmodels/list-item';
import { User } from '../viewmodels/user';
import { environment } from '../../environments/environment';
//import {  Response, RequestOptions, URLSearchParams, Headers, ResponseContentType, RequestOptionsArgs } from '@angular/http';
import { PieData } from '../viewmodels/pie_data';
import { PieDataContainer } from '../viewmodels/pie_data_container';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


import { Observable } from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators'

@Injectable()
export class DataService {
  constructor(private http: HttpClient) {
        
  }
  private baseurl: string = environment.apiUrl;
 
  //Hämtar länkar till applikationer
  getItems(ticket: string): Promise<Item[]> {

      let p = new Promise<Item[]>((resolve, reject) => {
          this.http.get(this.baseurl + 'landing?ticketStr=' + ticket).toPromise().then((x: any) => {
              let data: Array<Item> = x;
              resolve(data);
          });
      });

      return p;

  }
  //Hämtar användardata via ticket
  getUser(ticket: string): Promise<User> {
      let p = new Promise<User>((resolve, reject) => {
          this.http.get(this.baseurl + 'landing/GetUser?ticket=' + ticket).toPromise().then((x: any) => {
              let user: User = x;
              resolve(user);
          });
      });
      return p;
  }
  //Hämtar kpi-data för es och revisioner
  getKpiDataES(ticket: string): Promise<PieDataContainer> {
    let p = new Promise<PieDataContainer>((resolve, reject) => {
      this.http.get(this.baseurl + 'landing/GetKpiEs?ticket=' + ticket).toPromise().then((kpiData: any) => {        
        resolve(kpiData);
      });
    });
    return p;
  }
  //Hämtar kpi-data som föder pie-diagrammen
  getKpiData(ticket: string): Promise<PieDataContainer> {
  let p = new Promise<PieDataContainer>((resolve, reject) => {
          this.http.get(this.baseurl + 'landing/GetKpiData?ticket=' + ticket).toPromise().then((x: any) => {
              
              resolve(x);
          });
      });
      return p;
  }
  //Skickar AnvändarID till servern som i sin tur skapar ett konto och ett tillfälligt lösenord som skickas till den anställdes epost-adress
  registerAccount(emCode: string): Promise<any> {

      let p: Promise<any> = new Promise((resolve, reject) => {
          //let options = this.getHeaders();
          //options.headers.append('Content-Type', 'application/json');

          var data = { EmCode: emCode };
        this.http.post(this.baseurl + 'account/register', JSON.stringify(data), { headers: { "Content-Type": "application/json" } }).toPromise().then((x: any) => {
              console.log('Test OK');
              resolve({ message: 'Ett epost-meddelande innehållande ett temporärt lösenord har skickats till dig.' });
          }).catch(errors => {
              reject(errors);
          });
      });
      return p;
  }
  recoverAccount(emCode: string): Promise<any> {

      let p: Promise<any> = new Promise((resolve, reject) => {
            
          //let options = this.getHeaders();
          //options.headers.append('Content-Type', 'application/json');

        var data = { EmCode: emCode };
        this.http.post(this.baseurl + 'account/recover', JSON.stringify(data), { headers: { "Content-Type": "application/json" }}).toPromise().then((x: any) => {
              console.log('Test OK');
              resolve({ message: 'Ett epost-meddelande innehållande ett temporärt lösenord har skickats till dig.' });
          }).catch(errors => {
              reject(errors);
          });
      });
      return p;
  }
  changePasswordLogin(emCode: string, oldPassword: string, newPassword: string, newPasswordConfirm: string) {
      console.log(arguments);
      let p: Promise<any> = new Promise((resolve, reject) => {
          //let options = this.getHeaders();
          //options.headers.append('Content-Type', 'application/json');

          var data = { EmCode: emCode, OldPassword: oldPassword, newPassword: newPassword, NewPasswordConfirm: newPasswordConfirm };
        this.http.post(this.baseurl + 'account/login', JSON.stringify(data), { headers: { "Content-Type": "application/json" } }).toPromise().then((x: any) => {
              console.log('Test OK');
              resolve({ message: 'Du är nu klar och kan använda Happy!' });
          }).catch(errors => {
              reject(errors);
          });
      });
      return p;
  }
  //Validerar det genererade lösenordet, returnerar SANT om lösenordet stämmer
  validateGeneratedPassword(password: string): Promise<boolean> {
    return new Promise((resolve, reject) => resolve(false));
  }
  //Registrerar ett nytt lösenord och returnerar SANT om operationen kunde utföras
  registerPassword(oldpassword:string, password: string): Promise<boolean>
  {
    return new Promise((resolve, reject) => resolve(false));
  }
  selectCostCenter(cc: number): Observable<any> {
    //let options = this.getHeaders();
    //options.headers.append('Content-Type', 'application/json');
    var ticket = localStorage.getItem('ticket');
    return this.http.post(this.baseurl + 'costcenter/select', {ticket: ticket, cc: cc });
  }
  getCostCenters(): Observable<any> {
    //let options = this.getHeaders();
    //options.headers.append('Content-Type', 'application/json');
    return this.http.get(this.baseurl + 'costcenter/getselections');
  }
}
