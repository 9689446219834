<app-nav></app-nav>
<div class="app-container">
    <div class="container">
        <router-outlet></router-outlet>
        <div class="alert alert-info" [hidden]="true" role="alert">{{info}}</div>
    </div>
</div>
<div class="app-footer">
  <div class="container" *ngIf="loggedIn">
    <div *ngIf="kpiData && !kpiData.isPartner" class="kpi-container">
      <app-kpi-es [esData]="kpiData.es"></app-kpi-es>
      <app-kpi-rev [revData]="kpiData.rev"></app-kpi-rev>
    </div>
    <div *ngFor="let container of pieData" [hidden]="!container.Visible">

      <h2 class="footer-header">{{container.Header}}</h2>
      <div style="width:50%;float:left;text-align:center;" kpi [item]="d" *ngFor="let d of container.items"></div>
      <div style="clear:both;"></div>
      <hr style="margin:20px 0 0 0;" />
    </div>
    <div class="copymark">
      &copy; {{currentYear}} Securitas Technology Sverige AB
    </div>
  </div>
    <div class="container" *ngIf="!loggedIn">
      <h2 class="footer-header hidden">KPI-data visas bara i inloggat läge</h2>
      <br/>
    </div>
</div>
<app-inactivity-modal></app-inactivity-modal>
