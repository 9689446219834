import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, ActivatedRoute, Params, RouterModule } from '@angular/router';
import { NgChartsModule } from 'ng2-charts';

import { DataService } from './services/data.service';
import { SharedModelService } from './services/shared-model.service';

import { AppComponent } from './app.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { StartComponent } from './components/start/start.component';
import { NavComponent } from './components/nav/nav.component';
import { KpiComponent } from './components/kpi/kpi.component';
import { RegisterComponent } from './components/login/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { RecoverComponent } from './components/login/recover/recover.component';
import { NotificationComponent } from './components/notifications/notifications.component';
import { Notification } from 'rxjs';
import { RouteGuard } from './services/happy.guard';
import { MyHttpInterceptor } from './services/http.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InactivityModal } from './components/modals/inactivity-modal.component';
import { KpiEsComponent } from './components/kpi-es/kpi-es.component';
import { KpiRevComponent } from './components/kpi-rev/kpi-rev.component';
//import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
@NgModule({
  declarations: [
    AppComponent,
    ListItemComponent,
    StartComponent,
    NavComponent,
    KpiComponent,
    RegisterComponent,
    LoginComponent,
    RecoverComponent,
    NotificationComponent,
    InactivityModal,
    KpiEsComponent,
    KpiRevComponent,
    //AuthCallbackComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    RouterModule.forRoot([
      {
        path: '', component: StartComponent,
        data: { allowAccessTo: ['user'] },
        canActivate: [RouteGuard]
      },
      {
        path: 'start', component: StartComponent,
        data: { allowAccessTo: ['user'] },
        canActivate: [RouteGuard]
      },
      //{
      //  path: 'auth-callback', component: AuthCallbackComponent,
      //  data: { allowAccessTo: ['user'] },
      //  canActivate: [RouteGuard]
      //},
      { path: 'register', component: RegisterComponent },
      { path: 'login', component: LoginComponent },
      { path: 'recover', component: RecoverComponent },
      {
        path: 'notifications', component: NotificationComponent,
        data: { allowAccessTo: ['user'] },
        canActivate: [RouteGuard]
      },
      //{ path: '**', component: StartComponent }
    ]),
    NgChartsModule
  ],
  providers: [DataService, SharedModelService, {
    provide: HTTP_INTERCEPTORS,
    useClass: MyHttpInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
