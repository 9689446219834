<div class="list-group list-item-group-ssc">
  <div *ngFor="let item of items; let i = index" class="custom-margin">
    <a
      [ngClass]="{'disabled' : !item.Visible, 'visible': item.Visible}"
      [href]="(item.Visible) ? item.ApplicationUrl : '#'" class="list-group-item" target="_blank" *ngIf="item.Visible">
      <i class="angle double right icon"></i>
      {{item.ApplicationName}}</a>
    <span class="list-group-item disabled" *ngIf="!item.Visible">{{item.ApplicationName}}</span>
  </div>
</div>
<div class="alert stanley" role="alert" *ngIf="!loggedOut">Du är inloggad som: <strong>{{Name}}</strong></div>
<div class="alert stanley"*ngIf="loggedOut">{{Message}}</div>

<!--<i class="red warning sign icon"></i> = Du har inte blivit tilldelad åtkomst till denna applikation.-->

