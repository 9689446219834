import { Component, AfterViewInit, OnInit } from '@angular/core';
import { PieData } from './viewmodels/pie_data';
import { PieDataContainer } from './viewmodels/pie_data_container';
import { DataService } from './services/data.service';
import { SharedModelService } from './services/shared-model.service';
interface KpiData
{
  isPartner: boolean,
  rev: KpiRev,
  es: any
}
interface KpiRev {
  type: string;
  datasets: Array<{data: Array<number[]> | number[], label: string, borderColor: string, backgroundColor: string | string []}>;
  labels: Array<any>;
  total: number;
  done: number;
  notDone: number;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit {
  currentYear: number = new Date().getFullYear();
  title = 'Securitas Technology - Applikationer';
  info = 'Information angående applikationerna';
  loggedIn!: boolean;
  kpiData: KpiData | undefined;
  pieData: Array<any> = new Array<any>();

  constructor(private service: DataService, private shared: SharedModelService) {
    this.shared.loggedInStatusChanged.subscribe(status => {
      this.loggedIn = status;
      if (this.loggedIn)
        this.getKpiData();
    });
  }
  ngOnInit() {
    this.checkLoginStatus();
  }
  ngAfterViewInit() {

  }

  checkLoginStatus() {
    if (localStorage.getItem('ticket') != null && localStorage.getItem('token') != null) {
      //this.auth.setIdentity(true);
      this.shared.setLoggedInStatus(true);
      this.getKpiData();
    }
    else {
      //this.auth.setIdentity(false);
      this.shared.setLoggedInStatus(false);
    }
  }
  getKpiData() {
    if (this.loggedIn) {
      this.service.getKpiDataES(localStorage.getItem('ticket') ?? '').then(data => {

        if (data.KpiRevision != null) {
          this.kpiData = this.convertDataToChartjs(data);
        }
        console.log(this.kpiData);

      });
    }
  }
  convertDataToChartjs(data: PieDataContainer): KpiData {
    let model = {
      isPartner: false,
      rev: {
        type: 'pie',
        datasets: [
          {
            data: data.KpiRevision.data.data,
            label: 'Revisioner',
            borderColor: '#999',
            backgroundColor: ['#031F30', '#32c232']
          }
        ],
        labels: data.KpiRevision.data.labels,
        total: data.KpiRevision.total,
        notDone: data.KpiRevision.notDone,
        done: data.KpiRevision.doneCount
      },
      es: {
        type: 'pie'
      }
    };
    return model;
  }
  openModal() {
    this.shared.showInactivityModal();
  }
  // events


}

