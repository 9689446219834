import { Injectable, EventEmitter } from '@angular/core';
declare var $: any;

@Injectable()
export class SharedModelService {
  inactivityNotificationSent: boolean = false;
  interval: number = 60000;
  public loggedInStatusChanged: EventEmitter<boolean>;

  constructor() {
      this.loggedInStatusChanged = new EventEmitter();
    
    setInterval(() => {
      var d = new Date();
      var expires = new Date(localStorage["expires"]);
      console.log(d, expires);
      if (expires < d)
        this.showInactivityModal();
    }, this.interval);
  }

    setLoggedInStatus(status: boolean)
    {
      if(status)
        this.inactivityNotificationSent = false;

        this.loggedInStatusChanged.emit(status);
    }

  showInactivityModal() {
    console.log('open', $);
    if (!this.inactivityNotificationSent) {
      $('#inactivityModal').modal({ show: true, backdrop: 'static' });
      this.inactivityNotificationSent = true;
    }
    
  }
}
