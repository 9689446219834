<div class="pie-container">
<!-- <canvas baseChart
  [type]="'pie'"
  [datasets]="pieChartDatasets"
  [labels]="pieChartLabels"
  [options]="pieChartOptions"
  [plugins]="pieChartPlugins"
  [legend]="pieChartLegend"
  (chartHover)="chartHovered($event)"
  (chartClick)="chartClicked($event)"
  >
</canvas> -->
<canvas baseChart
  [type]="'pie'"
  [datasets]="revData.datasets"
  [labels]="revData.labels"
  [options]="pieChartOptions"
        
  ></canvas>
  
  <div *ngFor="let l of revData.labels; let in = index">{{l}}: {{revData.datasets[0].data[in]}}</div>
<div>
  Totalt planerade: {{revData.total}}
</div>
</div>
