import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
export class ListItemComponent implements OnInit {
    @Input() header!: string;
    @Input() text!: string;
    @Input() href!: string;
    constructor() {

    }
    
  ngOnInit() {
  }

}
