import { Component, AfterViewInit, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationComponent {
  items: any[] = new Array();
  constructor(private data: DataService) {
    this.getSelections();
  }
  select(item: any) {
    item.selected = !item.selected;
    this.data.selectCostCenter(item.id).subscribe(x => { console.log('klart');});
  }
  getSelections() {
    this.data.getCostCenters().subscribe(x => {
      let list: Array<any> = x;
      this.items = list;
    });
  }
}
