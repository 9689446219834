import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //const { auth, router } = this;
    const { allowAccessTo } = next.data;
    console.log('CAN ACTIVATE');
    return true;
    //const identity = auth.getIdentity();
    //if (
    //  identity && identity.loggedIn &&
    //  allowAccessTo.indexOf(identity.role)
    //) {
    //  console.log('ALL GOOD', identity);
    //  // all good, proceed with activating route
    //  return true;
    //}
    //if (identity && identity.loggedIn) {
    //  console.log('403');
    //  // TODO show ErrorForbiddenViewComponent instead of redirecting
    //  console.log('403 Forbidden >>', next);
    //}
    //else {
    //  console.log('401');
    //  // not logged in: redirect to login page with the return url
    //  const [returnUrl, returnQueryParams] = state.url.split('?');
    //  console.log('401 Unauthorised >>', returnUrl, returnQueryParams, next);
    //  //router.navigate(['/login'], { queryParams: { returnUrl, returnQueryParams } });
    //}
    //return false;
  }
}
