<div class="kpi-es-container">
  <h3>{{esData.ccText}}</h3>
  <h3>{{esData.esText}}</h3>
  <table class="es-table">
    <tr *ngFor="let item of esData.items">
      <td>{{item.technicianName}}</td>
      <td>{{item.esCount}} kr</td>
    </tr>
  </table>
</div>
