import { Component, OnInit, Input } from '@angular/core';
import { PieData } from '../../viewmodels/pie_data';

@Component({
  selector: '[kpi]',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.css']
})
export class KpiComponent implements OnInit {
    @Input() item!: PieData;
    constructor() {
        //this.item = new PieData("",[""],[1]);
        console.log(this.item);
    }

  ngOnInit() {
  }
  public chartClicked(e: any): void {
      console.log(e);
  }

  public chartHovered(e: any): void {
      console.log(e);
  }
}
