import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-kpi-es',
  templateUrl: './kpi-es.component.html',
  styleUrls: ['./kpi-es.component.css']
})
export class KpiEsComponent implements OnInit {
  @Input() esData: any;  
  constructor(private service: DataService) {

    this.service.getKpiDataES(localStorage.getItem("ticket") ?? '').then((data:any) => {
      console.log(data);
      this.esData = data.KpiES;
    });
  }

  ngOnInit() {
  }

}
