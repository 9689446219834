import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-inactivity-modal',
  templateUrl: './inactivity-modal.component.html',
  styleUrls: ['./inactivity-modal.component.css']
})
export class InactivityModal {
  constructor(private router: Router) {

  }
  login() {
    this.router.navigate(['login']);
  }
}
