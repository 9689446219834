import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('intercepted request ... ');
    var token = window.localStorage.getItem('token');
    // Clone the request to add the new header.
    //var h = req.headers.set('Authorization', 'Bearer ' + token);
    //h = req.headers.set('Content-Type', 'application/json');

    const authReq = req.clone(
      { setHeaders: { 'Content-Type': 'application/json', 'Authorization' : 'Bearer ' + token } }
    );

    console.log('Sending request with new header now ...');

    //send the newly created request
    return next.handle(authReq).pipe(catchError(e => {
      console.log('Error Occurred');
      console.log(e);
      //return the error to the method that called it
      if(e.status == 401)
        this.router.navigate(['login']);
      return throwError(e);
      
    }))
    
    
   
  }
}
