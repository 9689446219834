import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModelService } from '../../services/shared-model.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  password!: string;

  username!: string;
  data: any;
  error!: boolean;
  errorMessage: string = 'Det gick inte att logga in, kontrollera dina inloggningsuppgifter och försök igen.';
  registerLink: string = environment.registerLink;
  recoverLink: string = environment.recoverLink;
  constructor(private http: HttpClient, private router: Router, private shared: SharedModelService) {

  }
  ngOnInit() {
    this.error = false;
    // this.username = localStorage.getItem('happyuser') ?? '';
    // this.password = 'Publikt!1919';
  }
  public enterLogin(e: any) {

    console.log(e);

  }
  login_openid() {
    //this.auth.startAuthentication();
  }
  logout_openid() {
    //this.auth.logout();
  }
  login() {
    localStorage.removeItem('ticket');
    localStorage.removeItem('name');
    localStorage.removeItem('token');
    //this.auth.setIdentity(false);
    this.shared.setLoggedInStatus(false);

    this.error = false;
    var h = new HttpHeaders();      
    h.set('Content-Type', 'application/x-www-form-urlencoded');
    var params = "username=" + this.username + "&password=" + encodeURIComponent(this.password) + "&grant_type=password";
    //var user = { client_id: "SO", grant_type: 'password', username: encodeURIComponent(this.username), password: encodeURIComponent(this.password), scope: 'resourceApi', client_secret: 'secret' };
    
    this.http.post(environment.loginUrl, params, { headers: h }).toPromise()
          .then((x: any) => {
            //var data = x.json();
            this.data = x;
            console.log('this.data', this.data);
            localStorage.setItem('token', this.data.access_token);
            var h2 = new Headers();
            h2.set('Authorization', 'Bearer ' + this.data.access_token);
            //this.http.get(environment.userDataUrl, {
            //  headers: h2
            //}).toPromise().then(y => {
            //  var loggedInUser = y.json();
            //  localStorage.setItem('user', loggedInUser);
            //  localStorage.setItem('ticket', loggedInUser.ticket);
            //  localStorage.setItem('happyuser', this.username);
            //  localStorage.setItem('expires', data[".expires"]);
            //  this.auth.setIdentity(true);
            //  this.shared.setLoggedInStatus(true);
            //  this.router.navigate(['start']);

            //});
            localStorage.setItem('ticket', this.data.ticket);
            localStorage.setItem('happyuser', this.username);
            localStorage.setItem('expires', this.data[".expires"]);
            this.shared.setLoggedInStatus(true);
            this.router.navigate(['start']);
          })
          .catch((e: any) => {
              this.error = true;              
          });
  }

  
}
